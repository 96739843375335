#home{
  /* The image used */
  background-image: url(../../../public/img/landing/bg_1.jpeg);

  /* Full height */
  height:100vh;
  width: 100vw;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#title{
  padding-top: 20%;
  padding-left: 8%;
}